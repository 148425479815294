const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://tserver.dvgeo.app' : 'http://192.168.100.106:3046',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://tserver.dvgeo.app' : 'http://192.168.100.106:3046',
    api: 'api/',
    apiSocket: 'tserver::1.2',
    nameDir: 'tserver',
    package: 'mx.tserver.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCAD6oT9A8rvIumTebCZ4iY7v49sqHRj4Q',
    appName: 'TServer',
    provider: '',
    colorPrimary: '#FB8C18',
    colorDark: '#003676',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'mx.tserver.passenger',
    playStoreDriverId: 'mx.tserver.driver',
    appStorePassengerId: '6689523766',
    appStoreDriverId: '6689523726',
    email: "Tservermx2@gmail.com",
};
export default config;
